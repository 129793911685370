import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import {useMarketMoveMetaDataMethods} from "../../store/formStore";
import Box from "@mui/material/Box";

interface UnsavedChangesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSave?: () => void;
  showSaveButton?: boolean;
}

const DataChange = ({name, oldDate, newDate}) => {
  return (
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: 2,
          }}
      >
        <Box
            sx={{
                flexBasis: "100%",
            }}
        >
          <Typography variant="subtitle1" sx={{ color: "#6c757d" }}>
            Current {name}:
          </Typography>
          <Typography variant="body2" sx={{ color: "primary" }}>
            {oldDate}
          </Typography>
        </Box>
        <Box
            sx={{
                flexBasis: "100%",
            }}
        >
          <Typography variant="subtitle1" sx={{ color: "#6c757d" }}>
            New {name}:
          </Typography>
          <Typography variant="body2" sx={{ color: "primary" }} dangerouslySetInnerHTML={{ __html: newDate }} />
        </Box>
      </Box>
  );
}

const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({
  isOpen,
  onClose,
  onDiscard,
  onSave,

  showSaveButton = true
}) => {
    const { formState, watch } = useMarketMoveMetaDataMethods();
    const [startDate, endDate, marketMoveName] = watch(["startDate", "endDate", "marketMoveName"]);

    return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <Typography>
            You have unsaved changes. Do you want to save them before closing?

            {formState?.dirtyFields?.marketMoveName && <DataChange name="Name" oldDate={formState.defaultValues.marketMoveName} newDate={marketMoveName} /> }
            {formState?.dirtyFields?.startDate && <DataChange name="Start Date" oldDate={formState.defaultValues.startDate} newDate={startDate} /> }
            {formState?.dirtyFields?.endDate && <DataChange name="End Date" oldDate={formState.defaultValues.endDate} newDate={endDate} />}
            {formState?.dirtyFields?.productConfigList && <div>Product config has been modified.</div>}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onDiscard} color="error">
          Discard
        </Button>
        {showSaveButton && onSave && (
          <Button onClick={onSave} color="primary" variant="contained">
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesDialog;