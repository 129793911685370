/**
 * @module productTableDataUtils
 *
 * This module provides utility functions for generating and managing data
 * for the product table.
 *
 * It includes functions for:
 * - Generating table data by combining store information, recap details,
 *   and product configurations.
 * - Creating column definitions for the product table based on product
 *   configurations.
 *
 * The module also defines interfaces for table rows and columns, which
 * are used throughout the application for consistent data structure.
 */

import { IStore } from "../../interfaces/IStore";
import { MarketMoveRunDetailSchema } from "../../schema/schemas";

export interface IPreviewTableRow {
  storeId: string;
  displayName: string;
  products: Record<string, MarketMoveRunDetailSchema>;
  exclusionReason: string[];
}

export function generatePreviewTableData(
  stores: IStore[],
  recapDetails: MarketMoveRunDetailSchema[] | null,
): IPreviewTableRow[] {
  if (!recapDetails?.length) return [];

  const storeMap = new Map(stores.map((store) => [store.seiid, store]));

  return recapDetails.reduce((acc, detail) => {
    const store = storeMap.get(detail.storeId);
    if (!store) return acc;

    const existingRow = acc.find((row) => row.storeId === detail.storeId);

    if (existingRow) {
      existingRow.products[detail.productId] = detail;
      if (detail.exclusionReason && !detail.includeFlag) {
        existingRow.exclusionReason.push(
          `\n${detail.productId}: ${detail.exclusionReason}`
        );
      }
    } else {
      acc.push({
        storeId: detail.storeId,
        displayName: store.displayname,
        exclusionReason: detail.exclusionReason && !detail.includeFlag
          ? [`${detail.productId}: ${detail.exclusionReason}`]
          : [],
        products: { [detail.productId]: detail },
      });
    }

    return acc;
  }, [] as IPreviewTableRow[]);
}
