import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { MaterialReactTable, MRT_ColumnDef, MRT_RowSelectionState, useMaterialReactTable } from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useMarketMoveCancel } from '../../../hooks/useMarketMoveCancel';
import { useMarketMoveRunMethods } from '../../../store/formStore';
import { tableTheme } from '../../../theme/theme';

interface CancelationTableProps {
    onSelectionChange: (selectedStoreIds: string[]) => void;
    setTotalStoreCount: any
}

interface TableDataItem {
    storeId: string;
    mmStatus: string;
}

const CancelationTable: React.FC<CancelationTableProps> = ({onSelectionChange, setTotalStoreCount}) => {
    const { watch: marketMoveRunWatch } = useMarketMoveRunMethods();
    const marketMoveRunDetails = marketMoveRunWatch('marketMoveRunDetails');
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
    const [cancelMarketMoveDialog, setCancelMarketMoveDialog] = useState(false);

    const { cancelMarketMove } = useMarketMoveCancel({
        onSuccess: () => {
            setCancelMarketMoveDialog(false);
            enqueueSnackbar('Market Move has been canceled successfully', { variant: 'success' });
        },
    });
    
    const clonedTableData = useMemo<TableDataItem[]>(() => {
        if (!marketMoveRunDetails) {
            return [];
        }

        const uniqueStores = new Set<string>();

        return marketMoveRunDetails
            .filter(detail => detail.includeFlag && detail.mmStatus !== 'CANCELED')
            .filter(detail => {
                if (!uniqueStores.has(detail.storeId)) {
                    uniqueStores.add(detail.storeId);
                    return true;
                }
                return false;
            })
            .map(detail => ({
                storeId: detail.storeId,
                mmStatus: detail.mmStatus,
            }));
    }, [marketMoveRunDetails]);

    const columns = useMemo<MRT_ColumnDef<TableDataItem>[]>(
        () => [
            {
                accessorKey: 'storeId',
                header: 'Store ID',
            },
        ],
        []
    );

    useEffect(() => {
        const selectedIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
        onSelectionChange(selectedIds);
        setTotalStoreCount(clonedTableData.length)
    }, [rowSelection, onSelectionChange]);

    const table = useMaterialReactTable({
        columns,
        data: clonedTableData,
        enableRowSelection: true,
        enableColumnFilters: true,
        enableSorting: true,
        enablePagination: false,
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
        getRowId: (row) => row.storeId.toString(),
        ...tableTheme,
    });

    const handleCancelDialogOpen = () => {
        setCancelMarketMoveDialog(true);
    };

    const handleCancelDialogClose = () => {
        setCancelMarketMoveDialog(false);
    };

    const handleConfirmCancel = async () => {
        enqueueSnackbar('Request sent to Cancel Whole Market Move... Please Wait.', {variant: 'info'});
        await cancelMarketMove('CANCELMM');
    };
    console.log(clonedTableData);
 
    if (clonedTableData.length === 0) {
        return (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Typography variant="h6" gutterBottom>
                        No stores are available for selection.
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="error" 
                        onClick={handleCancelDialogOpen}
                        sx={{ mt: 2 }}
                    >
                        Cancel Whole Market Move
                    </Button>
                </Box>

                <Dialog
                    open={cancelMarketMoveDialog}
                    onClose={handleCancelDialogClose}
                    aria-labelledby="cancel-dialog-title"
                    aria-describedby="cancel-dialog-description"
                >
                    <DialogTitle id="cancel-dialog-title">Confirm Cancellation of Whole Market Move</DialogTitle>
                    <DialogContent>
                        <DialogContent id="cancel-dialog-description">
                            Are you sure you want to cancel the entire market move? This action cannot be undone.
                        </DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmCancel} color="primary" variant={'contained'}>
                            Yes, Cancel
                        </Button>
                        <Button onClick={handleCancelDialogClose} color="error" variant={'contained'}>
                            No, Do Not Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return(
        <Box>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default CancelationTable;