import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IStore } from "../../../../interfaces/IStore";
import { MarketMoveStoreListSchema } from "../../../../schema/schemas";
import { useFormStore } from "../../../../store/formStore";
import useGeneralStore from "../../../../store/generalStore";
import { formatISODate } from "../../../../utils/textUtils";

enum MarketMovePresence {
  NEW = "NEW",
  EXISTING = "EXISTING",
  NONE = "NONE",
}

// Helper function to convert IStore to MarketMoveStoreListSchema
// This ensures consistency when creating new store list items
export const convertToMMStore = (
  store: IStore,
  includeFlag: boolean
): MarketMoveStoreListSchema => ({
  storeId: store.seiid,
  includeDate: includeFlag ? formatISODate() : "",
  includeFlag: includeFlag,
  flag: "NOUPDATE",
});

export const useStoreList = () => {
  const { listViewSelectedStores, elasticData: baseElasticData } = useGeneralStore();
  const {
    marketMoveMetaDataMethods: {
      setValue,
      watch,
      formState: { defaultValues },
    },
  } = useFormStore();

  const elasticData = useMemo(() => {
    return baseElasticData.filter((store) => !!store.seiid);
  }, [baseElasticData]);

  const [showAllStores, setShowAllStores] = useState(false);

  // Use watch to observe specific attributes
  const marketMoveId = watch("marketMoveId");
  const storeListFromForm = watch("storeList");
  const defaultStoreList = defaultValues.storeList;

  const getSelectedMarketMovePresence = useMemo((): MarketMovePresence => {
    if (marketMoveId === "") return MarketMovePresence.NEW;
    if (marketMoveId === null) return MarketMovePresence.NONE;
    return MarketMovePresence.EXISTING;
  }, [marketMoveId]);

  const storeList = useMemo(() => {
    if (storeListFromForm && storeListFromForm.length > 0) {
      return storeListFromForm;
    }
    const filteredStores = elasticData.filter((store) =>
      listViewSelectedStores.some(
        (item) => item.importcode === store.importcode
      )
    );

    const mappedStores = filteredStores.map((store) =>
      convertToMMStore(store, true)
    );

    return mappedStores;
  }, [marketMoveId, elasticData, listViewSelectedStores, storeListFromForm]);

  useEffect(() => {
    const presence = getSelectedMarketMovePresence;
    if (!elasticData || elasticData.length === 0) return;

    if (presence === MarketMovePresence.EXISTING && elasticData.length > 0) {
      const selectedStoreList = storeListFromForm || [];
      if (selectedStoreList.length === 0) {
        setValue("storeList", storeList, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    } else if (presence === MarketMovePresence.NEW) {
      setValue("storeList", storeList, {
        shouldDirty: false,
        shouldValidate: true,
      });
    }
  }, [marketMoveId, elasticData]);

  const workingElasticData = useMemo(() => {
    // Create lookup map for faster access
    const storeListMap = new Map(storeList.map((s) => [s.storeId, s]));

    const mapStore = (store: any) => {
      const storeListEntry = storeListMap.get(store.seiid);
      return {
        ...store,
        includeFlag: storeListEntry?.includeFlag ?? false,
        flag: storeListEntry?.flag ?? "NOUPDATE",
      };
    };

    return showAllStores
      ? elasticData.map(mapStore)
      : elasticData
          .filter((store) => storeListMap.has(store.seiid))
          .map(mapStore);
  }, [elasticData, storeList, showAllStores]);

  const determineFlag = (
    newIncluded: boolean,
    initialIncluded: boolean | undefined
  ): "UPDATE" | "NOUPDATE" =>
    newIncluded !== initialIncluded ? "UPDATE" : "NOUPDATE";

  const updateStoreItem = useCallback(
    (
      store: MarketMoveStoreListSchema,
      newIncluded: boolean
    ): MarketMoveStoreListSchema => {
      // console.debug("updateStoreItem", store, newIncluded);
      const initialStore = defaultStoreList?.find(
        (s) => s.storeId === store.storeId
      );
      let flag: "INSERT" | "UPDATE" | "REMOVE" | "NOUPDATE";
      if (!initialStore) {
        flag = "INSERT";
      } else if (store.flag == "REMOVE") {
        if (newIncluded) {
          if (newIncluded === initialStore.includeFlag) {
            flag = "NOUPDATE";
          } else {
            flag = "UPDATE";
          }
        } else {
          flag = "REMOVE";
        }
      } else if (store.flag == "UPDATE") {
        if (newIncluded === initialStore.includeFlag) {
          flag = "NOUPDATE";
        } else {
          flag = "UPDATE";
        }
      } else {
        flag = determineFlag(newIncluded, initialStore?.includeFlag);
      }
      return {
        ...store,
        includeFlag: newIncluded,
        includeDate: newIncluded ? formatISODate() : store.includeDate,
        flag,
      };
    },
    [determineFlag, defaultStoreList]
  );

  const selectionDiff = useCallback(
    (selectedStores: { storeId: string; selected: boolean }[]) => {
      // Get all unique store IDs
      const allStoreIds = new Set([
        ...defaultStoreList.map((s) => s.storeId),
        ...selectedStores.map((s) => s.storeId),
      ]);

      return Array.from(allStoreIds).map((storeId) => {
        const wasInOriginalList = defaultStoreList.some(
          (s) => s.storeId === storeId
        );
        const isSelectedNow = selectedStores.some(
          (s) => s.storeId === storeId && s.selected
        );
        const hasChangedIncludeFlag = storeList.some(
          (s) =>
            s.storeId === storeId &&
            defaultStoreList.some(
              (d) => d.storeId === storeId && d.includeFlag !== s.includeFlag
            )
        );

        let flag: "REMOVE" | "INSERT" | "UPDATE" | "NOUPDATE";

        if (wasInOriginalList && !isSelectedNow) flag = "REMOVE";
        else if (!wasInOriginalList && isSelectedNow) flag = "INSERT";
        else if (hasChangedIncludeFlag) flag = "UPDATE";
        else flag = "NOUPDATE";

        return { storeId, flag };
      });
    },
    [defaultStoreList, storeList]
  );

  const updateStoreFlags = useCallback(
    (
      storeChanges: {
        storeId: string;
        flag: "INSERT" | "UPDATE" | "REMOVE" | "NOUPDATE";
      }[]
    ) =>
      storeChanges.map(({ storeId, flag }) => {
        const storeInCurrentList = storeList.find(
          (store) => store.storeId === storeId
        );

        if (storeInCurrentList) {
          const isBeingRemoved = flag === "REMOVE";
          return {
            ...storeInCurrentList,
            flag,
            includeFlag: isBeingRemoved
              ? false
              : storeInCurrentList.includeFlag,
          };
        }

        const storeFromElasticData = elasticData.find(
          (store) => store.seiid === storeId
        );
        const newStoreEntry = convertToMMStore(storeFromElasticData, true);
        newStoreEntry.marketMoveId = Number(marketMoveId);
        return { ...newStoreEntry, flag };
      }),
    [elasticData, storeList]
  );

  const finalizeSelection = useCallback(
    (selectedStores: { storeId: string; selected: boolean }[]) => {
      const diffList = selectionDiff(selectedStores);
      const newStoreList = updateStoreFlags(diffList);
      setValue("storeList", newStoreList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [selectionDiff, updateStoreFlags, setValue]
  );

  const handleToggleAll = useCallback(() => {
    const allIncluded = storeList.every((store) => store.includeFlag);
    const newStoreList = storeList.map((store) =>
      updateStoreItem(store, !allIncluded)
    );

    setValue("storeList", newStoreList, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [storeList, setValue, updateStoreItem]);

  const handleToggleStore = useCallback(
    (importcodes: string | string[]) => {
      const codesToToggle = Array.isArray(importcodes)
        ? importcodes
        : [importcodes];

      const newStoreList = storeList.map((store) => {
        const shouldToggle = codesToToggle.includes(store.storeId);
        return shouldToggle
          ? updateStoreItem(store, !store.includeFlag)
          : store;
      });

      setValue("storeList", newStoreList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [storeList, setValue, updateStoreItem]
  );

  const handleToggle = useCallback(
    (importcodes?: string | string[]) => {
      if (!importcodes) {
        handleToggleAll();
      } else {
        handleToggleStore(importcodes);
      }
    },
    [handleToggleAll, handleToggleStore]
  );

  const resetStoreList = useCallback(() => {
    setValue("storeList", defaultStoreList, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [defaultStoreList, setValue]);

  return {
    storeList,
    workingElasticData,
    handleToggle,
    resetStoreList,
    showAllStores,
    setShowAllStores,
    finalizeSelection,
  };
};
