import { Grid } from "@mui/material";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { PriceMode } from "../../interfaces/enums";
import {
  MarketMoveRunDetailSchema
} from "../../schema/schemas";
import { formatValue } from "../../utils/priceFormatting";

const CustomTooltip = (props: TooltipProps) => (
  <Tooltip {...props} placement="top" arrow />
);

interface PreviewTablePriceCellProps {
  detail: MarketMoveRunDetailSchema;
  sortMode: PriceMode;
  isStale: boolean;
  stalePrice?: number; // New prop to indicate stale price
}


const getOffsetDisplay = (priceDifference: number) => {
  const color = priceDifference > 0 ? "green" : priceDifference < 0 ? "red" : "black";
  return <span style={{ color }}>{priceDifference.toFixed(2)}</span>;
};

const PreviewTablePriceCell = ({
  detail: value,
  sortMode,
  isStale,
  stalePrice,
}: PreviewTablePriceCellProps) => {
  const cellStyle = {
    height: "100%", 
    width: "100%",
    backgroundColor: isStale ? "rgba(255, 165, 0, 0.1)" : undefined,
    position: "relative" as const,
  };

  const renderCellContent = () => {
    // Case 1: Excluded or invalid value
    if (!value || value.excludePriceFlag || !value.includeFlag) {
      return <span>Excluded</span>;
    }

    // Case 2: No price and no override
    if (!value.newPrice && !value?.manualPriceOverrideFlag) {
      return <span>N/A</span>;
    }

    // Case 3: Normal price display
    const isOverride = value?.manualPriceOverrideFlag;
    const mainValue = sortMode === PriceMode.INCREMENTAL
      ? getOffsetDisplay(value.delta || 0)
      : formatValue(isOverride ? value.manualPrice : (value.newPrice || 0), PriceMode.ABSOLUTE, false);

    const supValue = sortMode === PriceMode.INCREMENTAL
      ? formatValue(isOverride ? value.manualPrice : (value.newPrice || 0), PriceMode.ABSOLUTE, false)
      : getOffsetDisplay(value.delta || 0);

    return (
      <>
        <span style={{ fontWeight: isOverride ? "bold" : "normal" }}>{mainValue}</span>
        <sup style={{ marginLeft: 4, fontWeight: isOverride ? "bold" : "normal" }}>{supValue}</sup>
      </>
    );
  };

  const tooltipTitle = () => {
    if (!value || value.excludePriceFlag || !value.includeFlag) {
      return value?.exclusionReason || "Price excluded";
    }
    
    let title = `Original Price: ${value.originalPrice}`;
    if (isStale && stalePrice !== undefined) {
      title += ` | Pending override: ${formatValue(stalePrice, PriceMode.ABSOLUTE, false)}`;
    }
    return title;
  };

  return (
    <CustomTooltip title={tooltipTitle()}>
      <Grid container alignItems="center" justifyContent="center" style={cellStyle}>
        <Grid item style={{ textAlign: "center" }}>
          {renderCellContent()}
        </Grid>
      </Grid>
    </CustomTooltip>
  );
};

export default PreviewTablePriceCell;