import { UseFormReturn } from "react-hook-form";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { RunStatus } from "../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveRunSchema,
  PreviewDetailsSchema,
  StoreProductConfigSchema,
} from "../schema/schemas";

const baseStoreProductConfig: Omit<
  StoreProductConfigSchema,
  "storeId" | "productId"
> = {
  includeFlag: true,
  manualPriceOverrideFlag: false,
  manualPrice: 0,
};

export interface UpdateStoreProductConfigParams {
  storeId: string;
  productId: string;
  updates: Partial<StoreProductConfigSchema>;
  resetForm?: boolean;
}

interface FormState {
  marketMoveMetaDataMethods: UseFormReturn<MarketMoveMetaDataSchema> | null;
  previewDetailsMethods: UseFormReturn<PreviewDetailsSchema> | null;
  marketMoveRunMethods: UseFormReturn<MarketMoveRunSchema> | null;
  actions: {
    setMarketMoveMetaDataMethods: (
      methods: UseFormReturn<MarketMoveMetaDataSchema>
    ) => void;
    setPreviewDetailsMethods: (
      methods: UseFormReturn<PreviewDetailsSchema>
    ) => void;
    setMarketMoveRunMethods: (
      methods: UseFormReturn<MarketMoveRunSchema>
    ) => void;
    updateStoreProductConfig: (params: UpdateStoreProductConfigParams) => void;
    getStoreProductConfig: (
      storeId: string,
      productId: string
    ) => StoreProductConfigSchema | undefined;
    setRunStatus: (status: RunStatus) => void;
    clearStoreProductConfig: (storeId: string, productId?: string) => void;
  };
}

export const useFormStore = create<FormState>()(
  devtools(
    (set, get) => ({
      marketMoveMetaDataMethods: null,
      previewDetailsMethods: null,
      marketMoveRunMethods: null,
      actions: {
        setMarketMoveMetaDataMethods: (methods) =>
          set({ marketMoveMetaDataMethods: methods }),
        setPreviewDetailsMethods: (methods) =>
          set({ previewDetailsMethods: methods }),
        setMarketMoveRunMethods: (methods) =>
          set({ marketMoveRunMethods: methods }),
        updateStoreProductConfig: ({
          storeId,
          productId,
          updates,
          resetForm = false,
        }: UpdateStoreProductConfigParams) => {
          const {
            previewDetailsMethods: {
              reset: pdReset,
              getValues: pdGetValues,
              setValue: pdSetValue,
              formState: {defaultValues: pdDefaultValues},
            },
            marketMoveMetaDataMethods: {
              getValues: mmGetValues,
              setValue: mmSetValue,
            },
          } = get();
          if (!pdGetValues) return;

          const selectedMarketMove = mmGetValues();

          if (
            !selectedMarketMove?.storeList?.filter(
              (s) => s.storeId === storeId
            )[0]?.includeFlag
          ) {
            mmSetValue(
              "storeList",
              selectedMarketMove?.storeList.map((s) => {
                if (s.storeId === storeId) {
                  return { ...s, includeFlag: true };
                }
                return s;
              }),
              {
                shouldValidate: true,
                shouldDirty: true,
              }
            );
            return;
          }
          const currentConfig = pdGetValues("storeProductConfig");
          const existingConfig = get().actions.getStoreProductConfig(
            storeId,
            productId
          );

          let updatedConfig: StoreProductConfigSchema[];

          if (existingConfig) {
            updatedConfig = currentConfig
              .map((config) => {
                if (
                  config.storeId === storeId &&
                  config.productId === productId
                ) {
                  return { ...config, ...updates };
                }
                return config;
              })
              .filter((config) => {
                if (
                  config.storeId === storeId &&
                  config.productId === productId
                ) {
                  const { ...configWithoutIds } = config;
                  return !(
                    JSON.stringify(configWithoutIds) ===
                    JSON.stringify(baseStoreProductConfig)
                  );
                }
                return true;
              });
          } else {
            const newConfig = {
              storeId,
              productId,
              ...baseStoreProductConfig,
              ...updates,
            };

            const {
              storeId: _s1,
              productId: _p1,
              ...newConfigWithoutIds
            } = newConfig;

            if (
              JSON.stringify(newConfigWithoutIds) !==
              JSON.stringify(baseStoreProductConfig)
            ) {
              updatedConfig = [...currentConfig, newConfig];
            } else {
              updatedConfig = currentConfig;
            }
          }

          if (resetForm) {
            pdReset({
              ...pdGetValues(),
              storeProductConfig: updatedConfig,
            });
          } else {
            pdSetValue("storeProductConfig", updatedConfig, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        },
        getStoreProductConfig: (storeId, productId) => {
          const { previewDetailsMethods } = get();
          if (!previewDetailsMethods) return undefined;

          const storeProductConfig =
            previewDetailsMethods.getValues().storeProductConfig;
          return storeProductConfig.find(
            (config) =>
              config.storeId === storeId && config.productId === productId
          );
        },
        setRunStatus: (status: RunStatus) => {
          const { marketMoveRunMethods } = get();
          if (marketMoveRunMethods) {
            marketMoveRunMethods.setValue("marketMoveRun.runStatus", status, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        },
        clearStoreProductConfig: (storeId, productId) => {
          const { previewDetailsMethods } = get();
          if (!previewDetailsMethods) return;

          const currentConfig =
            previewDetailsMethods.getValues().storeProductConfig;

          const updatedConfig = currentConfig.filter((config) => {
            if (productId) {
              return !(
                config.storeId === storeId && config.productId === productId
              );
            } else {
              return config.storeId !== storeId;
            }
          });

          previewDetailsMethods.setValue("storeProductConfig", updatedConfig, {
            shouldValidate: true,
            shouldDirty: true,
          });
        },
      },
    }),
    { name: "FormStore" }
  )
);

export const useMarketMoveMetaDataMethods = () =>
  useFormStore((state) => state.marketMoveMetaDataMethods);
export const usePreviewDetailsMethods = () =>
  useFormStore((state) => state.previewDetailsMethods);

export const useMarketMoveRunMethods = () =>
  useFormStore((state) => state.marketMoveRunMethods);
export const useFormActions = () => useFormStore((state) => state.actions);
