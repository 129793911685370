import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { useShallow } from 'zustand/react/shallow';
import { generatePreviewTableData } from "../component/previewTable/previewTableDataUtils";
import { RunStatus } from "../interfaces/enums";
import {
  MarketMoveRunDetailSchema,
  StoreProductConfigSchema
} from "../schema/schemas";
import { useFormActions, useFormStore } from "../store/formStore";
import useGeneralStore from "../store/generalStore";
import useMarketMoveStore from "../store/marketMoveStore";
import { useGetSingleMMRun } from "./marketMoveRunApiHooks";
import { useGetPreviewDetails } from "./previewDetailsApiHooks";
import { deriveConfigFromRecap } from "../utils/marketMoveUtils";

function transformData(
  data: MarketMoveRunDetailSchema[]
): MarketMoveRunDetailSchema[] {
  return data.map((item) => ({
    ...item,
    exclusionReason: item.exclusionReason ?? "",
  }));
}

function createDataMap(data: MarketMoveRunDetailSchema[]) {
  const dataMap = new Map<string, MarketMoveRunDetailSchema>();
  data.forEach((item) => {
    const key = `${item.storeId}-${item.productId}`;
    dataMap.set(key, item);
  });
  return dataMap;
}

function mergeRecapData(
  existingMarketMoveRecapDetails: MarketMoveRunDetailSchema[],
  previewDetails: MarketMoveRunDetailSchema[]
) {
  const existingDataMap = createDataMap(existingMarketMoveRecapDetails);

  return previewDetails.map((newItem) => {
    const key = `${newItem.storeId}-${newItem.productId}`;
    const existingItem = existingDataMap.get(key);

    return {
      ...newItem,
      mmRunRecapId: existingItem?.mmRunRecapId ?? newItem.mmRunRecapId,
      marketMoveId: existingItem?.marketMoveId ?? newItem.marketMoveId,
    };
  });
}

export const usePreviewData = () => {
  const { elasticData, showRecap, showPastMarketMoves } = useGeneralStore();
  const {
    previewDetailsFetchReq,
    setPreviewStale,
    disableEditing
  } = useMarketMoveStore(
    useShallow(state => ({
      previewDetailsFetchReq: state.previewDetailsFetchReq,
      setPreviewStale: state.setPreviewStale,
      disableEditing: state.disableEditing
    }))
  );
  const [localRecapData, setLocalRecapData] = useState(null);

  const {
    previewDetailsMethods: {
      reset: resetPreviewDetails,
      setValue: setPreviewDetailsValue,
      watch: watchPreviewDetails,
    },
    marketMoveMetaDataMethods: { watch: watchMetaData },
    marketMoveRunMethods: {
      setValue: setMarketMoveRunValue,
      watch: watchMarketMoveRun,
      formState: { defaultValues: marketMoveRunDefaultValues }
    },
  } = useFormStore();

  const selectedMarketMoveStoreList = watchMetaData('storeList');
  const selectedMarketMoveProductConfigList = watchMetaData('productConfigList');
  const selectedMarketMoveId = watchMetaData('marketMoveId');
  
  const previewDetails = watchPreviewDetails();
  
  const marketMoveRunRecapDetails = watchMarketMoveRun('marketMoveRunRecapDetails');
  const marketMoveRunStatus = watchMarketMoveRun('marketMoveRun.runStatus');
  const marketMoveRunId = watchMarketMoveRun('marketMoveRun.marketMoveRunId');
  const marketMoveRunDetails = watchMarketMoveRun('marketMoveRunDetails');

  const { isFetching: isMMRunFetching } = useGetSingleMMRun(
    marketMoveRunId
  );

  // console.debug("usePreviewData - previewDetails", previewDetails);
  const disabled = disableEditing || !previewDetailsFetchReq.shouldFetch;
  const {
    data: recapData,
    refetch: refetchRecapData,
    ...rest
  } = useGetPreviewDetails(previewDetails, {
    enabled: false,
    onSuccess: (data: MarketMoveRunDetailSchema[]) => {
      setPreviewStale(false);
      const existingMarketMoveRunData =
        marketMoveRunRecapDetails || [];
      console.debug(
        "usePreviewData - existingMarketMoveRunData",
        existingMarketMoveRunData
      );
      const transformedData = transformData(data);
      console.debug("usePreviewData - transformedData", transformedData);
      const mergedData = mergeRecapData(
        existingMarketMoveRunData,
        transformedData
      );
      console.debug("usePreviewData - mergedData", { mergedData , existingMarketMoveRunData});


      setLocalRecapData(mergedData);
      setMarketMoveRunValue("marketMoveRunRecapDetails", mergedData);
      const derivedConfig = deriveConfigFromRecap({
        recapDetails: mergedData,
        selectedMarketMoveStoreList: selectedMarketMoveStoreList,
      });
      console.debug("usePreviewData - previewDetails", 
        previewDetails);
      resetPreviewDetails({
        ...previewDetails,
        storeProductConfig: derivedConfig
      });
      enqueueSnackbar("Preview data fetched successfully", {
        variant: "success",
      });
    },
    onError: (error: AxiosError) => {
      console.error("usePreviewData - Error fetching preview data", error);
      enqueueSnackbar(`Error fetching preview data: ${error.message}`, {
        variant: "error",
      });
    },
  });


  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    console.debug("usePreviewData - previewDetailsFetchReq effect triggered", {
      previewDetailsFetchReq,
    });
    if (previewDetailsFetchReq.shouldFetch) {
      console.debug("usePreviewData - Updating preview details", {
        storeList: selectedMarketMoveStoreList,
        productConfigs: selectedMarketMoveProductConfigList,
      });

      console.debug(
        "usePreviewData - selectedMarketMove?.productConfigList",
        selectedMarketMoveProductConfigList
      );
      console.debug("usePreviewData - Updating store product configs", {
        recapDetails: marketMoveRunRecapDetails,
      });
      setPreviewDetailsValue("marketMoveStores", selectedMarketMoveStoreList.filter(store => store.includeFlag));
      setPreviewDetailsValue("marketMoveProductConfigs", selectedMarketMoveProductConfigList);
      // updateStoreProductConfigs(marketMoveRunDefaultValues.marketMoveRunRecapDetails);
      setIsReadyToFetch(true);
    }
  }, [previewDetailsFetchReq.counter]);

  useEffect(() => {
    if (!isInitialMount.current && isReadyToFetch) {
      console.debug("usePreviewData - Triggering refetch");
      refetchRecapData({
        queryKey: ["previewDetails", previewDetails],
      });
      setIsReadyToFetch(false);
    } else {
      isInitialMount.current = false;
    }
  }, [isReadyToFetch, previewDetails]);

  useEffect(() => {
    setLocalRecapData(null);
    setPreviewStale(true);
    console.debug(
      "usePreviewData - Reset local states. marketMoveId:",
      selectedMarketMoveId,
      "marketMoveRunId:",
      marketMoveRunId
    );
  }, [selectedMarketMoveId]);

  const { getStoreProductConfig } = useFormActions();



  const recapDataToUse = useMemo(() => {
    console.debug(
      "usePreviewData - recapDataToUse",
      localRecapData,
      marketMoveRunRecapDetails
    );
    return localRecapData?.length > 0
      ? localRecapData
      : marketMoveRunRecapDetails;
  }, [localRecapData, marketMoveRunRecapDetails]);

  const memoizedTableData = useMemo(() => {
    console.debug("usePreviewData - memoizedTableData", recapDataToUse);

    if (
      showPastMarketMoves ||
      (showRecap &&
        marketMoveRunStatus === RunStatus.INPROGRESS)
    ) {
      console.debug("usePreviewData - Using runDetails due to INFLIGHT status");
      return generatePreviewTableData(
        elasticData,
        marketMoveRunDetails,
      );
    }

    return generatePreviewTableData(elasticData, recapDataToUse);
  }, [
    elasticData,
    recapDataToUse,
    marketMoveRunStatus,
    marketMoveRunDetails,
  ]);

  return {
    recapData: recapDataToUse,
    tableData: memoizedTableData,
    callState: {
      shouldFetchPreview: previewDetailsFetchReq.shouldFetch,
      ...rest,
      isLoading: rest.isFetching || isMMRunFetching,
    },
  };
};
