import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface DialogBoxGenericProps {
  open: boolean;
  closeFun: () => void;
  dialogTitle: string;
  dialogContent: string;
  confirmFun: () => void;
}

const DialogBoxGeneric: React.FC<DialogBoxGenericProps> = ({
  open,
  closeFun,
  dialogTitle,
  dialogContent,
  confirmFun,
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeFun}
      aria-labelledby="cancel-dialog-title"
      aria-describedby="cancel-dialog-description"
    >
      <DialogTitle id="cancel-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContent id="cancel-dialog-description">
          {dialogContent}
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmFun} color="primary" variant={"contained"}>
          Yes, Cancel
        </Button>
        <Button onClick={closeFun} color="error" variant={"contained"}>
          No, Do Not Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBoxGeneric;
