import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HardwareOutlinedIcon from "@mui/icons-material/HardwareOutlined";
import LockIcon from "@mui/icons-material/Lock";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { IconButton, Tooltip } from "@mui/material";
import { MRT_ColumnDef, MRT_ColumnOrderState } from "material-react-table";
import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { PriceMode } from "../../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveRunDetailSchema,
  MarketMoveStoreListSchema,
  StoreProductConfigSchema,
} from "../../schema/schemas";
import { getUniqueProducts } from "../../store/actions";
import { UpdateStoreProductConfigParams } from "../../store/formStore";
import PriceField from "../product/PriceField";
import PreviewTableHeader from "./PreviewTableHeader";
import PreviewTablePriceCell from "./PreviewTablePriceCell";
import { IPreviewTableRow } from "./previewTableDataUtils";

interface PreviewTableContext {
  updateConfig: (params: UpdateStoreProductConfigParams) => void;
  getConfig: (
    storeId: string,
    productId: string
  ) => StoreProductConfigSchema | undefined;
  setValue: UseFormSetValue<MarketMoveMetaDataSchema>;
  handleIncludeToggle: (
    storeId: string,
    currentInclude: boolean
  ) => void;
  storeList: MarketMoveStoreListSchema[];
  showPastMarketMoves: boolean;
}

// Main export function
export function generatePreviewTableColumnDefs(
  recapData: MarketMoveRunDetailSchema[] | null,
  sortMode: PriceMode,
  context: PreviewTableContext,
  showLinkedProducts: boolean,
  disableEditing: boolean,
  columnOrder: MRT_ColumnOrderState
): MRT_ColumnDef<IPreviewTableRow>[] {

  const columns = [...createBaseColumns(
    recapData,
    context,
    disableEditing
  )]

  if (!recapData?.length) {
    return sortColumnsByOrder(columns, columnOrder);
  }

  const productColumns = createProductColumns(
        recapData,
        sortMode,
        context,
        showLinkedProducts
      )
  const exclusionReasonColumn = createExclusionReasonColumn();

  
  
  // Sort columns based on columnOrder
  return sortColumnsByOrder([...columns, ...productColumns, exclusionReasonColumn], columnOrder);
}

function sortColumnsByOrder(
  columns: MRT_ColumnDef<IPreviewTableRow>[],
  columnOrder: MRT_ColumnOrderState
): MRT_ColumnDef<IPreviewTableRow>[] {
  const orderMap = new Map(columnOrder.map((id, index) => [id, index]));
  
  return [...columns].sort((a, b) => {
    const orderA = orderMap.get(a.id as string) ?? Infinity;
    const orderB = orderMap.get(b.id as string) ?? Infinity;
    return orderA - orderB;
  });
}

function createProductColumns(
  recapData: MarketMoveRunDetailSchema[],
  sortMode: PriceMode,
  context: PreviewTableContext,
  showLinkedProducts: boolean
): MRT_ColumnDef<IPreviewTableRow>[] {
  const uniqueProducts = getUniqueProducts();
  const productMap = new Map(uniqueProducts.map((p) => [p.id, p.name]));
  const uniqueProductIds = new Set(recapData.map((detail) => detail.productId));

  return Array.from(uniqueProductIds)
    .filter(
      (productId) =>
        showLinkedProducts || productId === "001" || productId === "019"
    )
    .map((productId) => {
      const productName = productMap.get(productId);
      return productName
        ? createProductColumn(
            productId,
            productName,
            sortMode,
            context
          )
        : null;
    })
    .filter(
      (column): column is MRT_ColumnDef<IPreviewTableRow> => column !== null
    );
}

// Column creation functions
const createBaseColumns = (
  recapData: MarketMoveRunDetailSchema[],
  context: PreviewTableContext,
  disableEditing: boolean
): MRT_ColumnDef<IPreviewTableRow>[] => [
  {
    accessorKey: "action",
    header: "Include",
    size: 60,
    enableEditing: false,
    Cell: ({ row }) => {
      let displayInclude = false;
      let functionalInclude = false;

      if (context.showPastMarketMoves) {
        functionalInclude = recapData.some(detail => detail.storeId === row.original.storeId && detail.includeFlag);
        displayInclude = functionalInclude;
      } else {
        const hasAnyConfigIncluded = Object.values(row.original.products || {}).some(product => {
          const config = context.getConfig(row.original.storeId, product.productId);
          return !config || config.includeFlag;
        });
        
        const hasAnyConfigExplicitlyIncluded = Object.values(row.original.products || {}).some(product => {
          const config = context.getConfig(row.original.storeId, product.productId);
          return config?.includeFlag;
        });

        const areAllProductsExcluded = Object.values(row.original.products || {}).every(product => !product.includeFlag);
        
        functionalInclude = hasAnyConfigIncluded;
        displayInclude = hasAnyConfigIncluded && (!areAllProductsExcluded || hasAnyConfigExplicitlyIncluded);
      }

      return (
        <Tooltip title={displayInclude ? "Exclude Store from StoreList" : "Include Store from StoreList"}>
          <span>
            <IconButton
              disabled={disableEditing || context.showPastMarketMoves}
              onClick={() => context.handleIncludeToggle(row.original.storeId, functionalInclude)}
            >
              {displayInclude ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
            </IconButton>
          </span>
        </Tooltip>
      );
    },
  },
  {
    accessorKey: "storeId",
    header: "Store ID",
    size: 70,
    enableEditing: false,
  },
  {
    accessorKey: "displayName",
    header: "Display Name",
    size: 130,
    enableEditing: false,
  },
];




const createExclusionReasonColumn = (): MRT_ColumnDef<IPreviewTableRow> => ({
  accessorKey: "exclusionReason",
  header: "Exclusion Reason",
  size: 250,
  enableEditing: false,
  Cell: ({ cell }) => (
    <ExclusionReasonCell exclusionReasons={cell.getValue() as string[]} />
  ),
  sortingFn: (rowA, rowB) => {
    const reasonsA = rowA.original.exclusionReason;
    const reasonsB = rowB.original.exclusionReason;

    // Sort by presence of exclusion reasons first
    if (reasonsA.length > 0 && reasonsB.length === 0) return -1;
    if (reasonsA.length === 0 && reasonsB.length > 0) return 1;

    // If both have reasons or both don't have reasons, sort by count
    return reasonsA.length - reasonsB.length;
  },
});

const ExclusionReasonCell: React.FC<{ exclusionReasons: string[] }> = ({
  exclusionReasons,
}) => {
  if (!exclusionReasons.length) return <div>N/A</div>;

  const truncatedText =
    exclusionReasons.length > 1
      ? `${exclusionReasons[0]} (+${exclusionReasons.length - 1})`
      : exclusionReasons[0];

  return (
    <Tooltip
      title={
        <div>
          {exclusionReasons.map((reason, index) => (
            <div key={index}>{reason}</div>
          ))}
        </div>
      }
      arrow
    >
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          padding: "4px",
        }}
      >
        {truncatedText}
      </div>
    </Tooltip>
  );
};

const isProductConfigStale = (
  config: StoreProductConfigSchema | undefined,
  value: MarketMoveRunDetailSchema
): boolean => {
  if (!config) return false;

  return (
    (config.manualPriceOverrideFlag && config.manualPrice !== value.newPrice) ||
    (config.manualPriceOverrideFlag !== undefined &&
      config.manualPriceOverrideFlag !== value.manualPriceOverrideFlag) ||
    (config.includeFlag !== value.includeFlag)
  );
};

const createProductColumn = (
  productId: string,
  productName: string | undefined,
  sortMode: PriceMode,
  context: PreviewTableContext
): MRT_ColumnDef<IPreviewTableRow> => {
  const { IconComponent, iconColor } = getPriceChangeIcon(sortMode, false);

  return {
    accessorFn: (row) => row.products?.[productId],
    id: productId,
    header: productName,
    size: 60,
    enableEditing: (row) => {
      const details: MarketMoveRunDetailSchema =
        row.original.products?.[productId];
      return details?.includeFlag && !details?.excludePriceFlag;
    },
    Edit: ({ cell, row, table }) => (
      <EditComponent
        cell={cell}
        row={row}
        table={table}
        productId={productId}
        context={context}
      />
    ),
    Header: ({ column }) => (
      <PreviewTableHeader
        productName={productName || ""}
        IconComponent={IconComponent}
        iconColor={iconColor}
      />
    ),
    sortingFn: (rowA, rowB, columnId) => {
      const productDataA = rowA.original.products[productId];
      const productDataB = rowB.original.products[productId];

      // Always keep included stores at the top
      if (productDataA.includeFlag !== productDataB.includeFlag) {
        return productDataA.includeFlag ? -1 : 1;
      }

      // If both are included or both are excluded, use the regular sorting
      return sortProducts(rowA, rowB, productId, sortMode);
    },
    Cell: ({ cell, row }) => {
      const value = cell.getValue() as MarketMoveRunDetailSchema;
      const storeId = row.original.storeId;
      const config = context.getConfig(storeId, productId);
      let isStale = false;
      let stalePrice = undefined;
      if (config && value) {
        isStale = isProductConfigStale(config, value);
        stalePrice = isStale ? config?.manualPrice : undefined;
      }

      return (
        <PreviewTablePriceCell
          detail={value}
          sortMode={sortMode}
          isStale={isStale}
          stalePrice={stalePrice}
        />
      );
    },
  };
};

// Sorting functions
const sortProducts = (
  rowA: any,
  rowB: any,
  productId: string,
  sortMode: PriceMode
) => {
  const productDataA = rowA.original.products[productId];
  const productDataB = rowB.original.products[productId];

  if (!productDataA || !productDataB) {
    return 0;
  }

  if (sortMode === PriceMode.ABSOLUTE) {
    return productDataB.newPrice - productDataA.newPrice;
  } else {
    // Use the delta field directly
    return productDataB.delta - productDataA.delta;
  }
};

// Component functions
const EditComponent = ({
  cell,
  row,
  table,
  productId,
  context,
}) => {
  const cellValue = cell.getValue() as MarketMoveRunDetailSchema;

  if (!cellValue) {
    table.setEditingCell(null);
    return <></>;
  };
  const initialPrice = cellValue.newPrice;

  const handlePriceChange = (newPrice: number | null) => {
    if (newPrice !== initialPrice) {
      updateStoreProductConfig(
        newPrice,
        cellValue,
        row.original.storeId,
        productId,
        context
      );
    }
    table.setEditingCell(null);
  };

  return (
    <PriceField
      price={initialPrice}
      setPrice={handlePriceChange}
      label={undefined}
      disabled={false}
    />
  );
};

// Utility functions
const updateStoreProductConfig = (
  newPrice: number | null,
  cellValue: MarketMoveRunDetailSchema,
  storeId: string,
  productId: string,
  context: PreviewTableContext
) => {
  if (newPrice !== null && newPrice > 0) {
    context.updateConfig({
      storeId,
      productId,
      updates: {
        manualPriceOverrideFlag: true,
        manualPrice: newPrice,
      },
    });
  } else {
    context.updateConfig({
      storeId,
      productId,
      updates: {
        manualPriceOverrideFlag: false,
        manualPrice: 0,
      },
    });
  }
};

export const getPriceChangeIcon = (
  priceChangeType: PriceMode,
  isOverridden: boolean
) => {
  if (isOverridden) {
    return { IconComponent: LockIcon, iconColor: "#da291c" };
  }
  const IconComponent =
    priceChangeType === PriceMode.ABSOLUTE
      ? HardwareOutlinedIcon
      : ChangeHistoryIcon;
  const iconColor =
    priceChangeType === PriceMode.ABSOLUTE ? "#007a53" : "#ff6720";
  return { IconComponent, iconColor };
};
